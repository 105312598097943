import logout from './assets/logout.png';

const LogoutButton = ({ auth }) => {
    return (
        <span className='navbar-brand' style={{ cursor: 'pointer' }} href='#' onClick={() => auth.signOut()}>
            <img
                src={logout}
                alt='logout'
                width='15'
                className='d-inline-block'
            />
            <span className='logout-t ms-2'>Wyloguj</span>
        </span>
    );
};

export default LogoutButton;
