import './Devices.css';
import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import logo_h from './assets/logo_h.svg';
import LogoutButton from './LogoutButton';
import './Grid.css';

const Devices = ({ devices, onCellClicked, auth }) => {
    const [searchPhrase, setSearchPhrase] = useState('');

    const filteredDevices = devices.reduce(
        (acc, device) =>         
            JSON.stringify(device).indexOf(searchPhrase) !== -1 ? [...acc, device] : acc,
        [],
            
    );
    const timeFormatter = (params) => new Date(params.value).toISOString().replace('T', ' ').slice(0, 19);
    const gridOptions ={ 
        columnDefs: [
            {
                flex: 6,
                headerName: 'NUMER SERYJNY URZĄDZENIA',
                field: 'serial',
                sortable: true,
            },
            {
                flex: 3,
                headerName: 'CZAS OSTATNIEGO TESTU',
                field: 'last_timestamp',
                valueFormatter: timeFormatter,
                sortable: true,
                sort: 'desc',
            },
            {             
                flex: 3,
                headerName: 'WERSJA APLIKACJI',
                field: 'soft_ver',
                sortable: true,

            },
            {
                flex: 4,
                headerName: 'OSTATNIE IP',
                field: 'last_IP',
                sortable: true,

            },
            { 
                flex: 2,
                headerName: 'ID UŻYTKOWNIKA',
                field: 'user_id',
                sortable: true,
            }

        ],
        rowHeight: 70,
        rowStyle: {
            'font-weight': 'normal',
            'font-size': '18px',
            'line-height': '21px',
            'color': '#281443',
            'background-color': '#FFFFFF',
        },
    };

    return (
        <div className='devices'>
            <nav className='navbar fixed-top navbar-light justify-content-center nav-set'>
                <div className='col-8'>
                    <div className='row justify-content-center'>
                        <div className='col-2'>
                            <span className='navbar-brand'>
                                <img src={logo_h} className='img-fluid' alt='Logo_h' />
                            </span>
                        </div>
                        <div className='col-4 text-center'>
                            <input
                                placeholder='Wpisz frazę'
                                className='form-control float-end'
                                type='text'
                                value={searchPhrase}
                                onChange={(event) => setSearchPhrase(event.target.value)}
                            />
                        </div>
                        <div className='col-3 '>
                            <span className='navbar-brand  dl-u float-end '>{auth.currentUser.email} </span>
                        </div>
                        <div className='col-2'>
                            <LogoutButton auth={auth} />
                        </div>
                    </div>
                </div>
            </nav>
            <div className='row m-1 justify-content-center nav-pad'>
                <div className='col-10'>
                    <p className='dl-text my-3'>Lista urządzeń</p>
                    <div
                        className='ag-theme-alpine'
                        style={{ height: 600, width: '100%' }}
                    >
                        <AgGridReact
                            rowData={filteredDevices}
                            onCellClicked={onCellClicked}
                            gridOptions={gridOptions}
                        />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Devices;
