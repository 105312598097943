import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Grid} from 'react-loader-spinner';

const Loading = () =>  {
    return (
        <div style={{
            position: 'absolute',
            top: 'calc(50% - 75px)',
            left: 'calc(50% - 75px)',
            }}> 
            <Grid arialLabel="loading-indicator" color="#5A3888" height={150} width={150} />
        </div>

    );
}

export default Loading;
