import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, query, startAt, endAt, orderByKey } from 'firebase/database';
import Login from './Login';
import Loading from './Loading';
import Devices from './Devices';
import Logs from './Logs';
import firebaseConfig from './firebaseConfig';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase(app);

function App() {
    auth.useDeviceLanguage();
    const [isLoading, setIsLoading] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState(undefined);    
    const [devices, setDevices] = useState([]);
    const [selectedSerial, setSelectedSerial] = useState(undefined);
    const [selectedDay, setSelectedDay] = useState(undefined);
    const [logs, setLogs] = useState([]);
    const [logsDays, setLogsDays] = useState([]);

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
            setIsLoading(true);
            setIsSignedIn(!!user);       
            const devicesDb = query(
                ref(db, 'device'),
            );
            onValue(devicesDb, (snapshot) => { 
                setIsLoading(false);
                const data = snapshot.val() ?? {};
                setDevices(
                    Object.keys(data).map((deviceId) => ({
                        device_ID: deviceId,
                        ...data[deviceId],
                    })),
                );
            },{onlyOnce: true});
        });
        return () => unregisterAuthObserver();

    }, []);

    useEffect(() => {
        setIsLoading(true); 
        const logsDy = ref(db, `logs_days/${selectedSerial}`);
        onValue(logsDy, (snapshot) => {
            setIsLoading(false); 
            const day_data = snapshot.val() ?? {};
            setLogsDays(           
                Object.keys(day_data)
            );
            
        },{onlyOnce: true});
    }, [selectedSerial]);

    useEffect(() => { 
        if (logsDays.slice(-1)[0]){        
            setSelectedDay(new Date(parseInt(logsDays.slice(-1)[0])));
        }
    }, [logsDays]);

    useEffect(() => {
        setIsLoading(true);               
        const logsDb = query(
            ref(db, `logs/${selectedSerial}`),            
            startAt(new Date(selectedDay).setHours(0,0,0,0).toString()),
            endAt(new Date(selectedDay).setHours(23,59,59,999).toString()),
            orderByKey(),                
        );
        onValue(logsDb, (snapshot) => {
            setIsLoading(false);
            const data = snapshot.val() ?? {};
            setLogs(               
                Object.keys(data).map((logId) => ({
                    log_ID: logId,
                    ...data[logId],
                })),
            );
        },{onlyOnce: true});
    }, [selectedDay]);

    const onCellClicked = (event) => {
        setIsLoading(true);
        setSelectedSerial(event.data.device_ID);
    } 
    const onViewDevices = () => {
        setLogs(undefined);                
        setSelectedSerial(undefined);
        setSelectedDay(undefined);
        setIsLoading(true);             
    };
    const selectedDevice = devices.find((device) => device.device_ID === selectedSerial);

    let content;
    content = <Loading />;
    if (isSignedIn === undefined) {
        content = <Loading />;
    } else if (!isSignedIn) {
        content = <Login auth={auth} />;       
    } else if (isLoading) {
        content = <Loading />;
    } else if (!selectedSerial) {
        content = <Devices devices={devices} onCellClicked={onCellClicked} auth={auth} />;
    } else {
        content = (
            <Logs
                logs={logs}
                onViewDevices={onViewDevices}
                auth={auth}
                selectedDevice={selectedDevice}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                logsDays={logsDays}
            />
        );
    }

    return <div className='App'>{content}</div>;
}

export default App;
