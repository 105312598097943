import './Login.css';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import logo_v from './assets/logo_v.png';
import logo_b from './assets/b.svg';
import m_w_l from './assets/mwl.svg';
import 'firebase/compat/auth';

const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
        signInSuccessWithAuthResult: () => false,
    },
};

const Login = ({ auth }) => {
    return (
        <div className='login'>
            <div className='bg-white rounded-lg'>
                <img src={logo_b} className='img-fluid lb-b' width='250px' alt='LB' />
                <img src={logo_v} className='img-fluid lb-v' width='250px' alt='Logo_v' />
                <div className='lf-u'>
                    <h4 className='h4-l m-b-sm text-center'>Logowanie do panelu </h4>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                </div>
            </div>
            <img src={m_w_l} className='img-fluid m-mwl' width='200px' alt='MWL' />
        </div>
    );
};

export default Login;
