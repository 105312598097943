import './Logs.css';
import { AgGridReact } from 'ag-grid-react';
import DeviceChart from './DeviceChart';
import logo_h from './assets/logo_h.svg';
import LogoutButton from './LogoutButton';
import './Grid.css';

const Logs = ({ logs, onViewDevices, auth, selectedDevice, selectedDay, setSelectedDay, logsDays }) => {
    const onCsvExportClicked = () => {
        const link = document.createElement('a');
        link.setAttribute(
            'href',
            encodeURI(
                `data:text/csv;charset=utf-8,CZAS,CND,ADRES_IP,DOWNLOAD,PING,WERSJA_APLIKAJCI\n${logs
                    .map((log) => {
                        var t = [];
                        t.push(
                            new Date(log.timestamp).toISOString().replace('T', ' ').slice(0, 19),
                            log.cdn,
                            log.ip,               
                            Math.round(log.download/1000/1000),
                            Math.round(log.ping),
                            log.soft_ver
                        )
                        return t.join(',')                       
                        }
                    )
                    .join('\n')}`,
            ),
        );
        const CSV_name =
            'device_logs_' +
            new Date().toISOString().replace('T', '_').replace(':', '_').slice(0, 19) +
            '.csv';
        link.setAttribute('download', CSV_name);
        document.body.appendChild(link);
        link.click();
    };
    const timeFormatter = (params) => new Date(params.value).toISOString().replace('T', ' ').slice(0, 19);
    const pingFormatter = (params) => Math.round(params.value) + ' ms';
    const downloadFormatter = (params) => Math.round(params.value/1000/1000) + ' Mbps';

    const gridOptions ={ 
        columnDefs: [
            {
                flex: 4,
                headerName: 'CZAS',
                field: 'timestamp',
                valueFormatter: timeFormatter,
            },
            {
                flex: 4,
                headerName: 'CDN',
                field: 'cdn'
            },
            {
                flex: 4,
                headerName: 'ADRES IP',
                field: 'ip'
            },
            {             
                flex: 2,
                headerName: 'DOWNLOAD',
                field: 'download',
                valueFormatter: downloadFormatter,
            },
            {
                flex: 2,
                headerName: 'PING',
                field: 'ping',
                valueFormatter: pingFormatter,
            },
            {
                flex: 3,
                headerName: 'WERSJA APLIKACJI',
                field: 'soft_ver',
            }

        ],
        rowHeight: 70,
        rowStyle: {
            'font-weight': 'normal',
            'font-size': '18px',
            'line-height': '21px',
            'color': '#281443',
            'background-color': '#FFFFFF',
        }
    };

    return (
        <div className='devices'>
            <nav className='navbar fixed-top navbar-light justify-content-center nav-set' style={{ height: '56px' }}>
                <div className='col-8'>
                    <div className='row justify-content-center'>
                        <div className='col-2'>
                            <span className='navbar-brand' href='#'>
                                <img src={logo_h} className='img-fluid' alt='Logo_h' />
                            </span>
                        </div>
                        <div className='col-4 text-center'>
                            <button
                                type='button'
                                className='btn btn-primary btn-diag float-end '
                                onClick={onViewDevices}
                            >
                                Wróć do listy urządzeń
                            </button>
                        </div>
                        <div className='col-3'>
                            <span className='navbar-brand  dl-u float-end '>{auth.currentUser.email} </span>
                        </div>
                        <div className='col-2'>
                            <LogoutButton auth={auth} />
                        </div>
                    </div>
                </div>
            </nav>
            <div className='row m-1 justify-content-center nav-pad'>
                <div className='col-10'>
                    <DeviceChart
                        logs={logs}
                        selectedDevice={selectedDevice}
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        logsDays={logsDays}
                    />

                    <div className='row m-1 nav-pad justify-content-between'>
                        <div className='col-6 mx-0'>
                            <p className='dl-text mt-3'>Logi</p>
                        </div>
                        <div className='col-6 mx-0'>
                            <button
                                type='button'
                                className='btn btn-primary btn-diag float-end'
                                onClick={onCsvExportClicked}
                            >
                                Export CSV
                            </button>
                        </div>
                    </div>
                    <div
                        className='ag-theme-alpine'
                        style={{ height: 800, width: '100%' }}
                    >
                        <AgGridReact rowData={logs} gridOptions={gridOptions}/>  

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logs;
