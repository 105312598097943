import { useRef, useEffect, forwardRef} from 'react';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import DatePicker, { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import 'chartjs-adapter-luxon';
import "react-datepicker/dist/react-datepicker.css";





ChartJS.register(...registerables, zoomPlugin, annotationPlugin);
registerLocale("pl", pl);


let timer;
let annoCdn;

const newTestTime = 300000;
const aggrTable = [
    [0, 5000, 1, 5000 ],
    [5, 5000, 1, 5000 ],
    [15, 10000, 1, 5000 ],
    [30, 15000, 1, 5000],
    [60, 20000, 1, 8000],
    [120, 30000, 1, 10000 ],
    [240, 60000, 1, 10000 ],
    [480, 60000, 0, 30000 ],
    [720, 80000, 0, 30000 ],
    [1440, 120000, 0, 30000 ]
];

const toRGB = (cdn, o) => {
    var hash = 0;
    for (var i = 0; i < cdn.length; i++) {
       hash = cdn.charCodeAt(i) + ((hash << 5) - hash);
    }
    var c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();
    var hex = '#'+ "00000".substring(0, 6 - c.length) + c;
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length/3 + '})', 'g')).map(function(l) { return parseInt(hex.length%2 ? l+l : l, 16) }).concat(isFinite(o) ? o : 1).join(',') + ')';   
    
}

const logsAgrregare = (logs, chartDiff) => {
    annoCdn = {};
    var newLogsAggr = [];
    var avgPing = 0;
    var avgDown = 0;
    var avgCount = 0;
    var avgFirst  = logs[0] ?? { timestamp: 0, cdn: 'unknown'};
    var aggrDiff = aggrTable.find((aggr) => aggr[0] > chartDiff);
    if (!aggrDiff){ aggrDiff = [1440, 120000, 0, 30000 ] };
    logs.forEach((log, i) => {
        var next = logs[i+1] ?? { timestamp: 0 };
        var diff = (next.timestamp - log.timestamp);      
        if ( diff < aggrDiff[1] && log.timestamp - avgFirst.timestamp < aggrDiff[1] && diff > 0 && log.cdn === avgFirst.cdn  ){
            avgCount =+ 1; 
            avgDown =+ log.download;
            avgPing =+ log.ping;          
        } else { 
            if (avgCount > 0){
                avgFirst.download = avgDown / avgCount; 
                avgFirst.ping = avgPing / avgCount ; 
                avgCount = 0;
                avgPing = 0; 
                avgDown = 0; 
                newLogsAggr.push(avgFirst);
                avgFirst = logs[i+1] ?? { timestamp: 0 };              
            } else { 
                newLogsAggr.push(log);
            }         
        }
    })
    var newLogs = [];
    newLogsAggr.forEach((log, i) => {
        const next = newLogsAggr[i+1] ?? { timestamp: 0 };
        if (next.timestamp - log.timestamp > newTestTime  ){
            newLogs = [...newLogs, log, {timestamp: NaN, download: NaN, ping: NaN}];
        } else {
            newLogs.push(log);
        }
    });
    if (aggrDiff[2] > 0){
        var prev = newLogs[0] ?? { cdn: 'unknown' };
        var p_prev = newLogs[0] ?? { cdn: 'unknown' };   
        newLogs.forEach((log, i) => {            
            const next = newLogs[i+1] ?? { cdn: 'unknown' };            
            if (next.cdn !== log.cdn){
                if (!isNaN(log.timestamp)){
                    var xMin;
                    var xMax;                          
                    if (p_prev.timestamp && prev.timestamp && !isNaN(p_prev.timestamp) && !isNaN(prev.timestamp) ){
                        if ((prev.timestamp - p_prev.timestamp)/2 > 0){
                            xMin = prev.timestamp - (prev.timestamp - p_prev.timestamp)/2;
                        } else { 
                            xMin = prev.timestamp - aggrDiff[3];
                        }                                
                    } else {
                        xMin = prev.timestamp - (aggrDiff[3]);
                    };
                    if (next.timestamp && log.timestamp){
                        if ((next.timestamp - log.timestamp)/2 > 0){
                            xMax = log.timestamp + (next.timestamp - log.timestamp)/2;
                        } else { 
                            xMax = log.timestamp + aggrDiff[3];
                        }                                
                    } else {
                        xMax = log.timestamp + (aggrDiff[3]);
                    }                           

                    annoCdn['anno_'+i] = {
                        type: 'box',
                        backgroundColor: toRGB(log.cdn, 0.1),
                        borderColor: toRGB(log.cdn, 0.2),
                        xMin: xMin,
                        xMax: xMax,
                        label: {
                            drawTime: 'afterDraw',
                            enabled: true,
                            color: toRGB(log.cdn, 0.8),
                            content: log.cdn.substr(0, log.cdn.indexOf('.')),
                            position: {
                              x: 'center',
                              y: 'start'
                            }
                        }
                    };
                    prev = next;
                    p_prev = log;                
                } else {                     
                    prev = next;
                    p_prev = log;                    
                }            
            }  
        });
    }

    return getChartData(newLogs)   
}

const getChartData = (newLogs) => {
    return {
    labels: newLogs.map((log) => new Date(parseInt(log.timestamp))),
    datasets: [
        {
            type: 'line',
            label: 'Średni download [Mbps]',
            borderColor: '#5A3888',
            borderWidth: 2,
            fill: false,
            data: newLogs.map((log) => Math.round(log.download/1000/1000)),
            yAxisID: 'y',
        },
        {
            type: 'bar',
            label: 'PING [ms]',
            backgroundColor: '#14A38A',
            borderColor: 'white', 
            borderWidth: 2,
            barThickness: 6,
            maxBarThickness: 6,                      
            data: newLogs.map((log) => Math.round(log.ping)),
            yAxisID: 'y1',
        },
    ],
    }
};

const options = (selectedDay, logs) => ({
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    elements: {
        point: {
            radius: 2,
        },
        line: {
            borderWidth: 1.5,
        },
    },
    transitions:{ zoom: { animation: { duration :0 }}},
    scales: {
        x: {
            min: new Date(selectedDay).setHours(0,0,0,0),
            max: new Date(selectedDay).setHours(23,59,59,999),
            type: 'time',
            time: {
                displayFormats: {
                    hour: 'HH:mm',
                    minute: 'HH:mm:ss',
                    second: 'HH:mm:ss',
                }
            },
            adapters: {
                date: {
                    zone: 'utc+1',
                    locale: 'pl'
                }
            },

            
            ticks: {
                color: '#9F9FA6',
            },
            grid: {
                color: '#F0F0F0',
                borderDash: [2],
            },
        },
        y: {
            type: 'linear',
            grace: '20%',
            display: true,
            position: 'left',
            ticks: {
                color: '#5A3888',
                callback: function (value, index, values) {
                    return value + ' Mbps';
                },
            },
            grid: {
                color: '#F0F0F0',
                borderDash: [2],
            },
        },
        y1: {
            type: 'linear',
            grace: '50%',
            display: true,
            position: 'right',
            ticks: {
                color: '#14A38A',
                callback: function (value, index, values) {
                    return value + ' ms';
                },
            },
            grid: {
                color: '#F0F0F0',
                borderDash: [2],
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        zoom: {
            limits: {
                x: {
                    min: new Date(selectedDay).setHours(0,0,0,0),
                    max: new Date(selectedDay).setHours(23,59,59,999),
                    minRange: 600000,
                }
            },
            zoom: {

                wheel: {
                    enabled: true,
                },
                drag: {
                    enabled: true,
                },
                mode: 'x',
                speed: 100,
                onZoomComplete({chart}) {
                    const {min, max} = chart.scales.x;
                    const chartDiff = (max - min)/1000/60;     
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        chart.data = logsAgrregare(logs, chartDiff);
                        chart.options.plugins.annotation.annotations = annoCdn;
                        chart.stop();
                        chart.update('none');
                      }, 1000);    
                },

            },
            pan: {
                enabled: true,
                mode: 'x',
                speed: 100,
                modifierKey: 'ctrl',
                onPanComplete({chart}) {
                    const {min, max} = chart.scales.x;
                    const chartDiff = (max - min)/1000/60;                         
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        chart.data = logsAgrregare(logs, chartDiff);
                        chart.options.plugins.annotation.annotations = annoCdn;                       
                        chart.stop();
                        chart.update('none');
                      }, 1000);    
                }, 

            },

        },
        autocolors: false,
        annotation: {
            annotations: annoCdn,
          }
    },
});
const DeviceChart = ({ logs, selectedDevice, selectedDay, setSelectedDay, logsDays}) => {
    const chartDiff = (new Date(selectedDay).setHours(23,59,59,999) -new Date(selectedDay).setHours(0,0,0,0))/1000/60;
    const chartRef = useRef(null);    
    const resetZoom = () => chartRef.current.resetZoom();
    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <button className="btn btn-primary btn-diag" onClick={onClick} ref={ref}>
          {value}
        </button>
      ));

    useEffect(() => {
        return resetZoom;
    }, []);

    return (
        <div className='row m-1 justify-content-center nav-pad'>
                <div className='col-12'>
                    <div className='row m-1 justify-content-center nav-pad'>
                        <div className='col-8'>
                            <h4 className='dl-text'>{selectedDevice.serial}<span className='dl-dot mx-2'>•</span>{selectedDevice.soft_ver}<span className='dl-dot mx-2'>•</span>{selectedDevice.last_IP}</h4>
                        </div>
                        <div className='col-2'>                 
                            <button type="button" className="btn btn-primary btn-diag float-end me-3 " onClick={resetZoom}>Reset zoom</button>
                        </div>
                        <div className='col-2'>                            
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                showPopperArrow={false}
                                selected={selectedDay}
                                locale="pl"
                                onChange={(date) => setSelectedDay(date)}
                                highlightDates={logsDays.map(logDay => new Date(parseInt(logDay)))}
                                customInput={<DateInput />}
                            />                            
                        </div>
                        
                    </div>                     
                    <div style={{ height: 600, width: '100%' }}>
                        <Chart
                            ref={chartRef}
                            type='bar'
                            data={logsAgrregare(logs, chartDiff)}
                            options={options(selectedDay, logs)}
                        />
                    </div>
                </div>
                
        </div>
    );
};

export default DeviceChart;
