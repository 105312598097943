const firebaseConfig = {
  apiKey: "AIzaSyCPh96wDpM7f5QDYXnWkoLonIIu2gIEer4",
  authDomain: "play-now-mobile.firebaseapp.com",
  databaseURL: "https://play-now-box-network.europe-west1.firebasedatabase.app/",
  projectId: "play-now-mobile",
  storageBucket: "play-now-mobile.appspot.com",
  messagingSenderId: "360016589964",
  appId: "1:360016589964:web:68c1a48ba0ce09879337a9",
  measurementId: "G-CDYLY9DLB1"
};

export default firebaseConfig;
